<template>
  <div class="w-full min-h-screen bg-eliteverde">
    <Menu :lang="lang" />
    <div class="bg-gray-50 min-h-screen">
      <div class="mt-14 p-8 text-center font-serif uppercase">
        <h1 class="text-3xl">{{ title[lang] }}</h1>
      </div>
      <div class="container mx-auto">
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 pb-8">
          <a
            v-for="p in posts"
            :key="p._id"
            :href="p.slug ? '#/post/' + p.slug : '#/blog/' + p._id"
            class="w-full border-2 text-center"
          >
            <div
              class="
                bg-slate-200
                p-8
                font-serif
                text-xl
                bold
                h-40
                grid grid-cols-1
                place-content-center
                relative
              "
            >
              <img
                src="elite/SVG/icono.svg"
                class="absolute top-2 right-2 w-8"
              />
              <h2 class="uppercase">{{ p.titulo }}</h2>
            </div>
            <div class="grid grid-cols-1 bg-gray-100 p-2">
              <p><img src="elite/SVG/logo.svg" class="h-6 inline" /></p>
            </div>
            <div class="h-40 p-6">
              <p v-html="contenido(p)" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import { LoremIpsum } from "lorem-ipsum";
import Menu from "./Menu.vue";
const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});
//lorem.generateWords(1);
//lorem.generateSentences(5);
lorem.generateParagraphs(7);
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "BlogLista",
  components: { Menu },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: Api.servidor,
      cdn: Api.cdn + "/",
      empresa: Api.obtenerLocal("bdlg.mx.empresa") || {},
      esMovil: Api.esMovil(),
      title: {
        es: "Aprende Derecho con los mejores",
        en: "Learn law with the best",
      },
      subtitle: {
        es: "Nuestras publicaciones",
        en: "Our posts",
      },
      posts: [
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
      ],
    };
  },
  methods: {
    verPost(indice) {
      this.$router.push("/blog/" + indice);
    },
    contenido(p) {
      try {
        return (
          ((p || {}).contenido || "")
            .replace(/\\n/g, "<br />")
            .substring(0, 180) + "..."
        );
      } catch (e) {
        console.error(e, p);
        return "";
      }
    },
  },
  async mounted() {
    console.log("Blog", LoremIpsum);
    try {
      let posts = await axios.post(
        this.url + "/readbypass/postpublic",
        {},
        { headers: { "wst-appkey": "elite-abogados" } }
      );
      this.posts = posts.data.sort((a, b) => (a.fecha < b.fecha ? 1 : -1));
      console.log("posts?", posts.data, this.posts);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
